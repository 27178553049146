
<template>
  <div class="openBanner">
    <van-nav-bar  left-arrow  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                  class="nav" >
      <template #title>
        <p class="title">活动介绍</p>
      </template>
    </van-nav-bar>
    <div class="html">
      <van-loading class="load-box" type="spinner" size="40" v-if="loading">加载中...</van-loading>
      <p v-html="html"></p>
   </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
export default {
  name: "product",
  /*components: { Certification, TopBar },*/
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const showDetail = ref(false);
    const showImage = ref(false)
    let productImgs = ref("");
    const loading=ref()
    const html=ref();
    const state = reactive({
      listData: [{
        imgSrc: "http://gankai.gitee.io/vue-jd-h5/img/store3.cc632dc8.png",
        colorName: "黑色",
        selected: false
      }
      ]
    });

    onMounted(async () => {
      const url = $router.currentRoute.value.query.url;
      if (url && url.length > 0) {
        loading.value = true
        proxy.$http.get(url).then(res => {
          loading.value = false
          html.value =res.data
        }).catch(() => {
            loading.value = false
            html.value = '加载失败'
          })
      }
    });
    console.log(html)
    const goBack = (() => {
      $router.back(-1)
    })
    return {
      goBack,
      ...toRefs(state),
      html,
      loading
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


